/* rem and em do NOT depend on htl font-size on media queriesocial-nav-containers
1 rem = 1em = 16px 

convert pixels to em by 1200px / 16px
*/

/* 1350px / 16px = 84em */
/* @media (max-width: 84em) {
} */

/* 1400px */
@media (max-width: 87.5em) {
  .hero-container {
    max-width: 120rem;
  }
  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    font-size: 1.8rem;
  }
}

/* 1250px */
@media (max-width: 78em) {
  .social-nav-container {
    padding: 1.6rem;
  }
}

/* 1160px */
@media (max-width: 72.5em) {
  /* hero */
  .hero-main-header {
    font-size: 4.4rem;
  }

  .hero-image-container {
    padding: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    width: 45rem;

    /* position: absolute;
    right: 5%;
    top: 10%; */
  }

  .main-nav-list .main-nav-link {
    font-size: 1.6rem;
  }

  .hero-lists {
    max-width: 11rem;
    gap: 0.8rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    font-size: 1.8rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    padding: 1.2rem 2.4rem;
  }

  /* gallery */
  .btn-gallery,
  .btn-gallery:link,
  .btn-gallery:visited {
    font-size: 2.2rem;
    padding: 1.6rem 3.2rem;
  }

  /* price */
  .card-container {
    column-gap: 3.2rem;
  }

  .card-holder {
    width: 28rem;
    height: 38rem;
  }

  .price {
    font-size: 2.2rem;
  }

  .price-symbol {
    font-size: 6rem;
  }

  .card-title {
    font-size: 2.8rem;
  }

  .description-holder {
    font-size: 1.6rem;
    list-style: none;
    line-height: 1.5;
  }

  .ribbon {
    width: 22rem;
    top: 7%;
    right: -25%;
  }
}

/* 1080px */
@media (max-width: 67.5em) {
  /* nav bar */
  .nav-list-container {
    gap: 4.8rem;
  }

  .nav-list-container {
    gap: 2.4rem;
    padding: 2.4rem;
  }

  .main-nav-list .main-nav-link {
    font-size: 1.2rem;
  }

  .main-nav-list .main-nav-link {
    font-size: 1.6rem;
  }

  /* social nav */
  .nav-icons {
    font-size: 2.2rem;
  }

  /* hero related */
  .hero-lists {
    max-width: 10rem;
    gap: 0.8rem;
  }

  .related-text {
    font-size: 0.8rem;
  }

  /* biography */

  .biography-text {
    font-size: 2rem;
  }

  /* pricing */
  .slide {
    padding: 2.4rem 3.2rem;
  }

  .slide-header {
    font-size: 2.8rem;
  }

  .slide-comment {
    line-height: 2.8rem;
    font-size: 1.8rem;
  }

  .author {
    font-size: 1.7rem;
  }

  /* CTA */

  .cta-button,
  .cta-button:link,
  .cta-button:visited {
    padding: 2.8rem 5.6rem;
    font-size: 2.2rem;
  }
}

/* 1020px */
@media (max-width: 63.75em) {
  /* hero section */

  .hero-lists {
    max-width: 8rem;
    gap: 0.8rem;
  }

  .hero-image-container {
    width: 40rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    font-size: 1.6rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    padding: 1.2rem 2.4rem;
  }

  .nav-icons {
    font-size: 2rem;
  }

  .related-text {
    font-size: 0.75rem;
  }

  /* biography */
  .biography-text {
    font-size: 2.2rem;
  }

  .biography-text-container {
    flex-direction: column;
  }

  .biography-image-container {
    /* max-width: 36rem; */
    width: 60rem;
  }

  /* gallery */
  .gallery-header {
    font-size: 3.8rem;
  }
  .gallery-image-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  /* CTA */
  .cta-button,
  .cta-button:link,
  .cta-button:visited {
    padding: 2.4rem 4.8rem;
    font-size: 2rem;
  }

  .cta-social-link,
  .cta-social-link:link,
  .cta-social-link:visited {
    font-size: 3.4rem;
  }

  /* Pricing */
  .card-container {
    column-gap: 2.8rem;
  }

  .card-holder {
    width: 24rem;
    height: 36rem;
  }

  .ribbon {
    font-size: 1rem;
    width: 22rem;
    top: 7%;
    right: -25%;
  }

  .slider {
    width: 75rem;
    height: 24rem;
    padding: 4.8rem;
  }

  .slider-button,
  .slider-button:link,
  .slier-button:visited {
    width: 4rem;
    height: 4rem;
  }

  .slider-testimonial-icon {
    font-size: 4rem;
  }

  .price {
    font-size: 1.8rem;
  }

  .price-symbol {
    font-size: 5.4rem;
  }

  .card-title {
    font-size: 2.4rem;
  }

  .description-holder {
    font-size: 1.5rem;
    list-style: none;
    line-height: 1.5;
  }

  .btn,
  .btn:link,
  .btn:visited {
    font-size: 1.8rem;
    padding: 0.8rem 1.6rem;
  }

  /* footer */
  .copyright {
    font-size: 1.2rem;
  }
}

/* 920px */
/* Tablets */

@media (max-width: 57.5em) {
  html {
    /* 8rem */
    font-size: 50%;
  }

  .btn,
  .btn:link,
  .btn:visited {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }

  /* header */

  /* nav-button */
  .nav-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50rem;
    transition: all 0.3s;
  }

  .nav-container .nav-open {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    /* transform: translateX(0); */
    z-index: 10;
  }

  .btn-mobile-nav {
    display: block;
    z-index: 10;
    transition: all 0.3s;
  }

  .nav-open .nav-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .nav-list-container {
    background: rgb(255, 255, 255, 0.95);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in;

    opacity: 0;
    pointer-events: none;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .nav-list-container {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .section-header-light {
    font-size: 3.2rem;
    line-height: 1.2;
  }

  .section-subheader-light {
    font-size: 2.4rem;
    letter-spacing: 0.3rem;
  }

  .section-header-dark {
    font-size: 3rem;
  }

  .section-subheader-dark {
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 0.3rem;
  }

  /* hero section */

  .hero-lists {
    max-width: 7rem;
    gap: 0.8rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 2.8rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    font-size: 1.4rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    padding: 1rem 2rem;
  }

  .nav-icons {
    font-size: 2.4rem;
  }

  .hero-image-container {
    width: 50rem;
  }

  .related-text {
    font-size: 0.65rem;
  }

  /* biography */
  .biography-text {
    font-size: 2.4rem;
    width: 70rem;
  }

  /* gallery */

  .gallery-header {
    font-size: 3.6rem;

    font-weight: 600;

    padding: 5rem;
  }

  .btn-gallery,
  .btn-gallery:link,
  .btn-gallery:visited {
    font-size: 2rem;
    padding: 1.4rem 2.8rem;
  }

  /* PRICE */
  .slider-button,
  .slider-button:link,
  .slier-button:visited {
    width: 3.8rem;
    height: 3.8rem;
  }

  .slider-testimonial-icon {
    font-size: 3.8rem;
  }

  .slide {
    padding: 2.4rem 3.2rem;
  }

  .slide-header {
    font-size: 2.4rem;
  }

  .slide-comment {
    line-height: 2.8rem;
    font-size: 1.6rem;
  }

  .author {
    font-size: 1.5rem;
  }

  .card-container {
    column-gap: 2rem;
  }

  .card-holder {
    width: 24rem;
    height: 34rem;
  }

  .slider {
    width: 65rem;
    height: 24rem;
    padding: 4.8rem;
  }

  .ribbon {
    font-size: 0.9rem;
    width: 19rem;
    top: 6%;
    right: -26%;
  }
  .price {
    font-size: 1.8rem;
  }

  .price-symbol {
    font-size: 4.4rem;
  }

  .card-title {
    font-size: 2rem;
  }

  .description-holder {
    font-size: 1.4rem;
    list-style: none;
    line-height: 1.5;
  }

  /* CTA */
  .cta-header {
    font-size: 4.4rem;
  }

  .cta-social-container {
    width: 70rem;
  }

  .cta-button,
  .cta-button:link,
  .cta-button:visited {
    padding: 2.4rem 4.8rem;
    font-size: 2rem;
  }

  .cta-social-link,
  .cta-social-link:link,
  .cta-social-link:visited {
    font-size: 3rem;
  }
}

/* under 720px */
@media (max-width: 45em) {
  .grid-column-2 {
    grid-template-columns: 1fr;
  }

  /* social nav */
  .nav-icons {
    font-size: 2.4rem;
  }

  /* Hero section */

  .hero-container {
    height: 140rem;
    text-align: center;
    /* margin-bottom: 18rem; */
  }

  .nav-container {
    gap: 30rem;
  }

  .hero-lists {
    max-width: 15rem;
    gap: 0.8rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    font-size: 2.2rem;
    justify-content: space-evenly;
  }

  .related-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .related-text {
    font-size: 1.4rem;
  }

  .hero-image-container {
    max-width: 50rem;
    height: 60rem;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    padding: 0;
  }

  /* Biography */
  .biography-text {
    font-size: 2.4rem;
    width: 58rem;
  }

  .biography-image-container {
    /* max-width: 36rem; */
    width: 50rem;
  }
  /* Gallery Section */
  .gallery-header {
    font-size: 3.4rem;
  }

  .gallery-image-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .btn-gallery,
  .btn-gallery:link,
  .btn-gallery:visited {
    grid-column: 1 / -1;
  }

  /* price section */
  .slider {
    width: 60rem;
    height: 24rem;
  }

  .card-container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-self: center;
    row-gap: 2.4rem;
  }

  .card-title {
    font-size: 2.4rem;
  }

  .card-holder {
    margin: 0 auto;
    width: 26rem;
    height: 36rem;
  }

  .ribbon {
    font-size: 1rem;
    width: 19rem;
    top: 6%;
    right: -24%;
  }

  .card-holder:last-child {
    grid-column: 1 / -1;
    justify-content: center;
    align-self: center;
  }

  .description-holder {
    font-size: 1.6rem;
    list-style: none;
    line-height: 1.5;
  }
  /*CTA section */

  .cta-social-container {
    width: 60rem;
  }

  /* Modal view */
  .modal img {
    height: auto;
    padding: 4.8rem;
    width: 100vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* under 600px */
@media (max-width: 37.5em) {
  /* Hero */
  .hero-image-container {
    max-width: 40rem;
  }

  .hero-lists {
    width: 9rem;
    gap: 0.8rem;
  }

  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    font-size: 1.8rem;
  }

  .hero-lists {
    max-width: 11rem;
    gap: 0.8rem;
  }

  .nav-container {
    gap: 20rem;
  }
  /* biography */
  .biography-text {
    font-size: 2.2rem;
    width: 48rem;
  }

  .biography-image-container {
    /* max-width: 36rem; */
    width: 40rem;
  }

  /* Gallery */
  .gallery-image-container {
    grid-template-columns: 1fr;
    max-width: 45rem;
    margin: 0 auto;
  }

  /* Prices */

  .header-price {
    text-align: center;
  }

  .card-container {
    grid-template-columns: 1fr;
    justify-content: center;
    align-self: center;
    row-gap: 4.8rem;
  }

  .card-holder {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 32rem;
    height: 48rem;
    box-shadow: 0 0.1rem 1rem #333;

    padding: 2.4rem;
    border-top-left-radius: 4.8rem;
    border-bottom-right-radius: 4.8rem;
    position: relative;
    overflow: hidden;
  }

  .ribbon {
    width: 24rem;
    max-height: 0.8rem;
    padding: 1.6rem 2.4rem;
    background-color: gold;

    font-size: 1.2rem;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 6%;
    right: -25%;

    transform: rotate(45deg);
  }

  .card-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    row-gap: 1.2rem;
    color: #000;
  }

  .price {
    font-size: 2.4rem;
  }

  .price-pence {
    color: #333;
  }

  .price-symbol {
    font-size: 8rem;
  }

  .card-title {
    font-size: 3.2rem;
  }

  .description-holder {
    font-size: 1.8rem;
    list-style: none;
    line-height: 1.5;
  }

  .description-icon {
    font-size: 1.6rem;
    color: green;
    padding-right: 1rem;
  }

  .slider {
    width: 36rem;
    height: 36rem;
    margin-bottom: 4.8rem;
  }

  .slider-button,
  .slider-button:link,
  .slier-button:visited {
    width: 3.4rem;
    height: 3.4rem;
  }

  .slider-testimonial-icon {
    font-size: 3.2rem;
  }

  .slide {
    padding: 2rem 3rem;
  }

  .slide-header {
    font-size: 2.8rem;
  }

  .slide-comment {
    line-height: 2.6rem;
    font-size: 1.8rem;
  }

  .button-next {
    right: 0;
    top: 70%;
    transform: translateX(100%);
  }

  .button-prev {
    left: 0;
    top: 70%;
    transform: translateX(-100%);
  }

  /* CTA */
  .cta-header {
    font-size: 3.2rem;
  }

  .cta-button,
  .cta-button:link,
  .cta-button:visited {
    padding: 2rem 4.4rem;
    font-size: 2rem;
  }

  .cta-social-container {
    width: 30rem;
  }

  /* footer */
  .footer-container {
    padding: 4rem;
  }
}

/* under 420 px */
@media (max-width: 26.25em) {
  .btn,
  .btn:link,
  .btn:visited {
    display: inline-block;
    background-color: #000;
    color: #fff;
    font-size: 2rem;
    padding: 1rem 2rem;
    border-radius: 0.8rem;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s;

    /*for .btn only  */
    border: none;
  }
  /* header */
  .nav-container {
    gap: 8rem;
  }
  /* Hero */
  .hero-button,
  .hero-button:link,
  .hero-button:visited {
    font-size: 1.6rem;
    padding: 0.8rem 1.8rem;
  }

  .hero-lists {
    max-width: 8rem;
    gap: 0.8rem;
  }

  .hero-image-container {
    width: 30rem;
  }

  /* Biography */
  .biography-text {
    font-size: 2rem;
    line-height: 1.4;
    width: 35rem;
  }

  .biography-image-container {
    /* max-width: 36rem; */
    width: 25rem;
  }

  /* gallery */
  .gallery-header {
    font-size: 2.4rem;
  }
  .gallery {
    margin: 0 auto;
  }
  .gallery-image {
    margin: 0 auto;
  }
  .gallery-image-container {
    max-width: 35rem;
  }
  /* Prices */
  .card-holder {
    width: 28rem;
    height: 40rem;
  }

  .ribbon {
    font-size: 1.2rem;
    width: 19rem;
    top: 8%;
    right: -20%;
  }
  .price {
    font-size: 2.4rem;
  }

  .price-symbol {
    font-size: 4.8rem;
  }

  .card-title {
    font-size: 2.4rem;
  }

  .description-holder {
    font-size: 1.8rem;
    list-style: none;
    line-height: 1.5;
  }

  .slider {
    width: 30rem;
    height: 26rem;
  }
  /* CTA */
  .cta-social-container {
    width: 30rem;
  }
}
