@media (width <= 87.5em) {
  .hero-container {
    max-width: 120rem;
  }

  .hero-button, .hero-button:link, .hero-button:visited {
    font-size: 1.8rem;
  }
}

@media (width <= 78em) {
  .social-nav-container {
    padding: 1.6rem;
  }
}

@media (width <= 72.5em) {
  .hero-main-header {
    font-size: 4.4rem;
  }

  .hero-image-container {
    justify-content: center;
    align-items: center;
    width: 45rem;
    padding: 4.8rem;
    list-style: none;
    display: flex;
  }

  .main-nav-list .main-nav-link {
    font-size: 1.6rem;
  }

  .hero-lists {
    gap: .8rem;
    max-width: 11rem;
  }

  .hero-button, .hero-button:link, .hero-button:visited {
    padding: 1.2rem 2.4rem;
    font-size: 1.8rem;
  }

  .btn-gallery, .btn-gallery:link, .btn-gallery:visited {
    padding: 1.6rem 3.2rem;
    font-size: 2.2rem;
  }

  .card-container {
    column-gap: 3.2rem;
  }

  .card-holder {
    width: 28rem;
    height: 38rem;
  }

  .price {
    font-size: 2.2rem;
  }

  .price-symbol {
    font-size: 6rem;
  }

  .card-title {
    font-size: 2.8rem;
  }

  .description-holder {
    font-size: 1.6rem;
    line-height: 1.5;
    list-style: none;
  }

  .ribbon {
    width: 22rem;
    top: 7%;
    right: -25%;
  }
}

@media (width <= 67.5em) {
  .nav-list-container {
    gap: 2.4rem;
    padding: 2.4rem;
  }

  .main-nav-list .main-nav-link {
    font-size: 1.6rem;
  }

  .nav-icons {
    font-size: 2.2rem;
  }

  .hero-lists {
    gap: .8rem;
    max-width: 10rem;
  }

  .related-text {
    font-size: .8rem;
  }

  .biography-text {
    font-size: 2rem;
  }

  .slide {
    padding: 2.4rem 3.2rem;
  }

  .slide-header {
    font-size: 2.8rem;
  }

  .slide-comment {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .author {
    font-size: 1.7rem;
  }

  .cta-button, .cta-button:link, .cta-button:visited {
    padding: 2.8rem 5.6rem;
    font-size: 2.2rem;
  }
}

@media (width <= 63.75em) {
  .hero-lists {
    gap: .8rem;
    max-width: 8rem;
  }

  .hero-image-container {
    width: 40rem;
  }

  .hero-button, .hero-button:link, .hero-button:visited {
    padding: 1.2rem 2.4rem;
    font-size: 1.6rem;
  }

  .nav-icons {
    font-size: 2rem;
  }

  .related-text {
    font-size: .75rem;
  }

  .biography-text {
    font-size: 2.2rem;
  }

  .biography-text-container {
    flex-direction: column;
  }

  .biography-image-container {
    width: 60rem;
  }

  .gallery-header {
    font-size: 3.8rem;
  }

  .gallery-image-container {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
  }

  .cta-button, .cta-button:link, .cta-button:visited {
    padding: 2.4rem 4.8rem;
    font-size: 2rem;
  }

  .cta-social-link, .cta-social-link:link, .cta-social-link:visited {
    font-size: 3.4rem;
  }

  .card-container {
    column-gap: 2.8rem;
  }

  .card-holder {
    width: 24rem;
    height: 36rem;
  }

  .ribbon {
    width: 22rem;
    font-size: 1rem;
    top: 7%;
    right: -25%;
  }

  .slider {
    width: 75rem;
    height: 24rem;
    padding: 4.8rem;
  }

  .slider-button, .slider-button:link, .slier-button:visited {
    width: 4rem;
    height: 4rem;
  }

  .slider-testimonial-icon {
    font-size: 4rem;
  }

  .price {
    font-size: 1.8rem;
  }

  .price-symbol {
    font-size: 5.4rem;
  }

  .card-title {
    font-size: 2.4rem;
  }

  .description-holder {
    font-size: 1.5rem;
    line-height: 1.5;
    list-style: none;
  }

  .btn, .btn:link, .btn:visited {
    padding: .8rem 1.6rem;
    font-size: 1.8rem;
  }

  .copyright {
    font-size: 1.2rem;
  }
}

@media (width <= 57.5em) {
  html {
    font-size: 50%;
  }

  .btn, .btn:link, .btn:visited {
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }

  .nav-container {
    justify-content: space-around;
    align-items: center;
    gap: 50rem;
    transition: all .3s;
    display: flex;
  }

  .nav-container .nav-open {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    z-index: 10;
  }

  .btn-mobile-nav {
    z-index: 10;
    transition: all .3s;
    display: block;
  }

  .nav-open .nav-list-container {
    z-index: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .nav-list-container {
    opacity: 0;
    pointer-events: none;
    visibility: visible;
    background: #fffffff2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    transition: all .3s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0);
  }

  .nav-open .nav-list-container {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .section-header-light {
    font-size: 3.2rem;
    line-height: 1.2;
  }

  .section-subheader-light {
    letter-spacing: .3rem;
    font-size: 2.4rem;
  }

  .section-header-dark {
    font-size: 3rem;
  }

  .section-subheader-dark {
    color: #fff;
    letter-spacing: .3rem;
    font-size: 2.4rem;
  }

  .hero-lists {
    gap: .8rem;
    max-width: 7rem;
  }

  .main-nav-link:link, .main-nav-link:visited {
    font-size: 2.8rem;
  }

  .hero-button, .hero-button:link, .hero-button:visited {
    padding: 1rem 2rem;
    font-size: 1.4rem;
  }

  .nav-icons {
    font-size: 2.4rem;
  }

  .hero-image-container {
    width: 50rem;
  }

  .related-text {
    font-size: .65rem;
  }

  .biography-text {
    width: 70rem;
    font-size: 2.4rem;
  }

  .gallery-header {
    padding: 5rem;
    font-size: 3.6rem;
    font-weight: 600;
  }

  .btn-gallery, .btn-gallery:link, .btn-gallery:visited {
    padding: 1.4rem 2.8rem;
    font-size: 2rem;
  }

  .slider-button, .slider-button:link, .slier-button:visited {
    width: 3.8rem;
    height: 3.8rem;
  }

  .slider-testimonial-icon {
    font-size: 3.8rem;
  }

  .slide {
    padding: 2.4rem 3.2rem;
  }

  .slide-header {
    font-size: 2.4rem;
  }

  .slide-comment {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }

  .author {
    font-size: 1.5rem;
  }

  .card-container {
    column-gap: 2rem;
  }

  .card-holder {
    width: 24rem;
    height: 34rem;
  }

  .slider {
    width: 65rem;
    height: 24rem;
    padding: 4.8rem;
  }

  .ribbon {
    width: 19rem;
    font-size: .9rem;
    top: 6%;
    right: -26%;
  }

  .price {
    font-size: 1.8rem;
  }

  .price-symbol {
    font-size: 4.4rem;
  }

  .card-title {
    font-size: 2rem;
  }

  .description-holder {
    font-size: 1.4rem;
    line-height: 1.5;
    list-style: none;
  }

  .cta-header {
    font-size: 4.4rem;
  }

  .cta-social-container {
    width: 70rem;
  }

  .cta-button, .cta-button:link, .cta-button:visited {
    padding: 2.4rem 4.8rem;
    font-size: 2rem;
  }

  .cta-social-link, .cta-social-link:link, .cta-social-link:visited {
    font-size: 3rem;
  }
}

@media (width <= 45em) {
  .grid-column-2 {
    grid-template-columns: 1fr;
  }

  .nav-icons {
    font-size: 2.4rem;
  }

  .hero-container {
    text-align: center;
    height: 140rem;
  }

  .nav-container {
    gap: 30rem;
  }

  .hero-lists {
    gap: .8rem;
    max-width: 15rem;
  }

  .hero-button, .hero-button:link, .hero-button:visited {
    justify-content: space-evenly;
    font-size: 2.2rem;
  }

  .related-container {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .related-text {
    font-size: 1.4rem;
  }

  .hero-image-container {
    justify-content: center;
    align-items: center;
    max-width: 50rem;
    height: 60rem;
    margin: 0 auto;
    padding: 0;
    display: flex;
  }

  .biography-text {
    width: 58rem;
    font-size: 2.4rem;
  }

  .biography-image-container {
    width: 50rem;
  }

  .gallery-header {
    font-size: 3.4rem;
  }

  .gallery-image-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .btn-gallery, .btn-gallery:link, .btn-gallery:visited {
    grid-column: 1 / -1;
  }

  .slider {
    width: 60rem;
    height: 24rem;
  }

  .card-container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-self: center;
    row-gap: 2.4rem;
  }

  .card-title {
    font-size: 2.4rem;
  }

  .card-holder {
    width: 26rem;
    height: 36rem;
    margin: 0 auto;
  }

  .ribbon {
    width: 19rem;
    font-size: 1rem;
    top: 6%;
    right: -24%;
  }

  .card-holder:last-child {
    grid-column: 1 / -1;
    justify-content: center;
    align-self: center;
  }

  .description-holder {
    font-size: 1.6rem;
    line-height: 1.5;
    list-style: none;
  }

  .cta-social-container {
    width: 60rem;
  }

  .modal img {
    width: 100vw;
    height: auto;
    padding: 4.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (width <= 37.5em) {
  .hero-image-container {
    max-width: 40rem;
  }

  .hero-lists {
    gap: .8rem;
    width: 9rem;
  }

  .hero-button, .hero-button:link, .hero-button:visited {
    font-size: 1.8rem;
  }

  .hero-lists {
    gap: .8rem;
    max-width: 11rem;
  }

  .nav-container {
    gap: 20rem;
  }

  .biography-text {
    width: 48rem;
    font-size: 2.2rem;
  }

  .biography-image-container {
    width: 40rem;
  }

  .gallery-image-container {
    grid-template-columns: 1fr;
    max-width: 45rem;
    margin: 0 auto;
  }

  .header-price {
    text-align: center;
  }

  .card-container {
    grid-template-columns: 1fr;
    justify-content: center;
    align-self: center;
    row-gap: 4.8rem;
  }

  .card-holder {
    border-top-left-radius: 4.8rem;
    border-bottom-right-radius: 4.8rem;
    justify-content: center;
    align-items: center;
    width: 32rem;
    height: 48rem;
    padding: 2.4rem;
    display: grid;
    position: relative;
    overflow: hidden;
    box-shadow: 0 .1rem 1rem #333;
  }

  .ribbon {
    background-color: gold;
    justify-content: center;
    align-items: center;
    width: 24rem;
    max-height: .8rem;
    padding: 1.6rem 2.4rem;
    font-size: 1.2rem;
    font-weight: 700;
    display: flex;
    position: absolute;
    top: 6%;
    right: -25%;
    transform: rotate(45deg);
  }

  .card-header {
    color: #000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.2rem;
    display: flex;
  }

  .price {
    font-size: 2.4rem;
  }

  .price-pence {
    color: #333;
  }

  .price-symbol {
    font-size: 8rem;
  }

  .card-title {
    font-size: 3.2rem;
  }

  .description-holder {
    font-size: 1.8rem;
    line-height: 1.5;
    list-style: none;
  }

  .description-icon {
    color: green;
    padding-right: 1rem;
    font-size: 1.6rem;
  }

  .slider {
    width: 36rem;
    height: 36rem;
    margin-bottom: 4.8rem;
  }

  .slider-button, .slider-button:link, .slier-button:visited {
    width: 3.4rem;
    height: 3.4rem;
  }

  .slider-testimonial-icon {
    font-size: 3.2rem;
  }

  .slide {
    padding: 2rem 3rem;
  }

  .slide-header {
    font-size: 2.8rem;
  }

  .slide-comment {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  .button-next {
    top: 70%;
    right: 0;
    transform: translateX(100%);
  }

  .button-prev {
    top: 70%;
    left: 0;
    transform: translateX(-100%);
  }

  .cta-header {
    font-size: 3.2rem;
  }

  .cta-button, .cta-button:link, .cta-button:visited {
    padding: 2rem 4.4rem;
    font-size: 2rem;
  }

  .cta-social-container {
    width: 30rem;
  }

  .footer-container {
    padding: 4rem;
  }
}

@media (width <= 26.25em) {
  .btn, .btn:link, .btn:visited {
    color: #fff;
    cursor: pointer;
    background-color: #000;
    border: none;
    border-radius: .8rem;
    padding: 1rem 2rem;
    font-size: 2rem;
    text-decoration: none;
    transition: all .3s;
    display: inline-block;
  }

  .nav-container {
    gap: 8rem;
  }

  .hero-button, .hero-button:link, .hero-button:visited {
    padding: .8rem 1.8rem;
    font-size: 1.6rem;
  }

  .hero-lists {
    gap: .8rem;
    max-width: 8rem;
  }

  .hero-image-container {
    width: 30rem;
  }

  .biography-text {
    width: 35rem;
    font-size: 2rem;
    line-height: 1.4;
  }

  .biography-image-container {
    width: 25rem;
  }

  .gallery-header {
    font-size: 2.4rem;
  }

  .gallery, .gallery-image {
    margin: 0 auto;
  }

  .gallery-image-container {
    max-width: 35rem;
  }

  .card-holder {
    width: 28rem;
    height: 40rem;
  }

  .ribbon {
    width: 19rem;
    font-size: 1.2rem;
    top: 8%;
    right: -20%;
  }

  .price {
    font-size: 2.4rem;
  }

  .price-symbol {
    font-size: 4.8rem;
  }

  .card-title {
    font-size: 2.4rem;
  }

  .description-holder {
    font-size: 1.8rem;
    line-height: 1.5;
    list-style: none;
  }

  .slider {
    width: 30rem;
    height: 26rem;
  }

  .cta-social-container {
    width: 30rem;
  }
}

/*# sourceMappingURL=index.ff1d7c1b.css.map */
